import './AboutMe.css'

type AboutMeBase = {
    profileImgSrc: string,
    name: string,
    title: string,
    children: JSX.Element
}
export const AboutMe = ({profileImgSrc, name, title, children}:AboutMeBase) => (<div id='about-me-card'>
    <img src={profileImgSrc} alt={`${name} profile`} />
    <h1>{name}</h1>
    <h2>{title}</h2>
    <div>{children}</div>
</div>)