import './Contact.css'
import { DisplayCard } from '../../Components/DisplayCard/DisplayCard'
import { NewsletterPromo } from '../../Components/NewsletterPromo/NewsletterPromo'
import { ContactForm } from '../../Components/ContactForm/ContactForm'

const PhoneIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Phone Portrait</title><rect x='128' y='16' width='256' height='480' rx='48' ry='48' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path d='M176 16h24a8 8 0 018 8h0a16 16 0 0016 16h64a16 16 0 0016-16h0a8 8 0 018-8h24' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/></svg>)

export const Contact = () => (<div id='contact'>
    <img src='https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/writing-kit.jpg' alt='Mountains' className='mobileOnly' />
    <div id='contact-hero'>
        <DisplayCard icon={<PhoneIcon />} title={`We'd love to hear from you!`} size='medium'>
            <div>
                <p>We write everything from resumes to action plans, to blogs, to email sequences, and everything in between.</p>
                <p>What can we write especially for you?</p>
                <ContactForm />
            </div>
        </DisplayCard>
    </div>
    <NewsletterPromo width='normal' />
</div>)