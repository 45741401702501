import './Samples.css'
import { Link } from 'react-router-dom'
import { IGButton } from '../../Components/IGButton/IGButton'

export const ProductsDeck = [
    {
        name: 'Talk About Predictable!',
        description: `A SyFy short story where all human behavior is predicted- until they do something that the algorithm did not expect. What happens now?`,
        imgSrcUrl: 'https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/book-covers/predictable-cover.jpg',
        destinationUrl: 'https://amzn.to/39ksCie'
    },
    {
        name: 'Top Ten Trials and Tribulations',
        description: `A dashing, young writer helps a talk show host create a comedy sketch. What goes on behind all the laughs? Who are the writers and what is their motivation? Go inside the mind of a comedy writer and see how he helps "the funniest guy on late night" to renew for next season. At the same time, our writer is on his own journey of self-discovery... what will he find?`,
        imgSrcUrl: 'https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/book-covers/trials-cover.jpg',
        destinationUrl: 'https://amzn.to/3iNzUOR'
    }
]

export const WorkSamplesDeck = [
    {
        name: '7 Rules in Proportion & Scale That Nail Your Interior Design',
        description: ``,
        slug: '7-rules-in-proportion-and-scale-that-nail-your-interior-design'
    },
    {
        name: 'Engaging Training',
        description: ``,
        slug: 'engaging-training'
    },
    {
        name: 'The Good Catch Incentive Program!',
        description: ``,
        slug: 'the-good-catch-incentive-program'
    },
    {
        name: 'Health Benefits of Marula Oil',
        description: ``,
        slug: 'heath-benefits-of-marula-oil'
    },
    {
        name: 'The 2020 Toyota Camry: Building on a Tradition of Excellence',
        description: ``,
        slug: 'the-2020-toyota-camry-building-on-a-tradition-of-excellence'
    },
    {
        name: 'Quiz Review',
        description: ``,
        slug: 'quiz-reivew'
    }
]

const PaperIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Newspaper</title><path d='M368 415.86V72a24.07 24.07 0 00-24-24H72a24.07 24.07 0 00-24 24v352a40.12 40.12 0 0040 40h328' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32'/><path d='M416 464h0a48 48 0 01-48-48V128h72a24 24 0 0124 24v264a48 48 0 01-48 48z' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M240 128h64M240 192h64M112 256h192M112 320h192M112 384h192'/><path d='M176 208h-64a16 16 0 01-16-16v-64a16 16 0 0116-16h64a16 16 0 0116 16v64a16 16 0 01-16 16z'/></svg>)

const PencilIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Pencil</title><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z'/></svg>)

const SamplesCard = (props: {icon?:JSX.Element, title:string, children:JSX.Element}) => (<div className='samples-card'>
    <div id='samples-card-icon'>{props.icon}</div>
    <h1>{props.title}</h1>
    {props.children}
</div>)

export const Samples = () => (<div id='samples'>
    <div id='samples-bio'>
        <h1>Work Samples</h1>
        <p>I come from 20 years of corporate safety work with all its advantages and disadvantages. I know what works as well as what doesn't.</p>
        <p>Whether it's training or compliance documentation, there's a lot of detail out there. You want your audience to understand your message or you've just wasted a lot of time writing it!</p>
        <p>That's where we come in. You're not alone. We can help!</p>
    </div>

    <div id='samples-columns'>
        <SamplesCard icon={<PaperIcon />} title='Example Work'>
            <ul>
                {WorkSamplesDeck.map((x, i) => (<li className='samples-sample-item' key={i}>
                    <Link to={`/samples/${x.slug}`}>{x.name}</Link>
                </li>))}
            </ul>
        </SamplesCard>
        <SamplesCard icon={<PencilIcon />} title='Amazon Books'>
            <div>
                {ProductsDeck.map((x, i) => (<div className='samples-product-item' key={i}>
                    <div>
                        <h1>{x.name}</h1>
                        <p>{x.description}</p>
                        <IGButton label='Open Amazon →' url={x.destinationUrl} internal={false} />
                    </div>
                    <img src={x.imgSrcUrl} alt={`${x.name} cover`} />
                </div>))}
            </div>
        </SamplesCard>
    </div>
</div>)