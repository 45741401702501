import './Footer.css'
import { Link } from 'react-router-dom'
import { NavLibrary } from '../Nav/Nav'

export const Footer = () => (<div id='footer'>
    <div id='footer-bio'>
        <h1>Culpepper Communications</h1>
        <p>“We write for you!”</p>
        <p><a href='https://chrisculpepper.substack.com' target='_blank' rel='noopener noreferrer'>Follow our newsletter on Substack →</a></p>
        <p><a href='https://www.freeprivacypolicy.com/live/5d43b48e-9be6-471e-9c21-7fbba60aca19' target='_blank' rel='noopener noreferrer'>Privacy Policy →</a></p>
    </div>
    <div id='footer-nav'>
        <h1>Navigation</h1>
        {NavLibrary.map((x, i) => (<Link to={x.path} target={`${x.name === 'Blog' ? '_blank' : ''}`} key={i} className='footer-nav-item'>
            {x.name}
        </Link>))}
    </div>
</div>)