import './WorkSampleStory.css'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

interface StoryJsonDataBase {
    id: '',
    url: '',
    title: '',
    content_html: ''
}

export const WorkSampleStory = () => {
    let { workSampleStorySlug } = useParams<{workSampleStorySlug:string}>()
    const [thisStoryData, setThisStoryData] = useState<StoryJsonDataBase>({id: '', url: '', title: '', content_html: ''})
    const WorkSampleStoryFeed = 'https://culpepper-communications.s3.us-east-1.amazonaws.com/Work-Samples/culpepper-communications-work-samples-feed.json'

    useEffect(() => {
        function fetchFeed(RSSUrl:string) {
            fetch(RSSUrl)
                .then(res => res.json())
                .then(jsonRes => {
                    jsonRes.items.find((x:StoryJsonDataBase) => {
                        return x.id === workSampleStorySlug ? (setThisStoryData(x)) : null
                    })
                })
        }
        fetchFeed(WorkSampleStoryFeed)
    }, [workSampleStorySlug])

    return (<div id='work-sample-story'>
        <Helmet>
            <title>Culpepper Communications - {thisStoryData.title}</title>
        </Helmet>
        <p><Link to='/samples'>← Back to Work Samples</Link></p>
        <div id='work-sample-story-body'>
            <h1>{thisStoryData.title}</h1>
            {parse(thisStoryData.content_html)}
        </div>
    </div>)
}