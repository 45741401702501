import './IGButton.css'
import { Link } from 'react-router-dom'

type IGButtonBase = {
    label: string,
    url: string,
    internal: boolean
}

export const IGButton = ({label, url, internal}:IGButtonBase) => (
    internal ?
        (<Link to={url} className='ig-button'>
            {label}
        </Link>)
    :
        (<a href={url} target='_blank' rel='noopener noreferrer' className='ig-button'>
            {label}
        </a>)
)