import './About.css'
import { AboutMe } from '../../Components/AboutMe/AboutMe'
import { NewsletterPromo } from '../../Components/NewsletterPromo/NewsletterPromo'

export const About = () => (<div id='about'>
    <h1>About Us</h1>
    <div id='about-profiles'>
        <AboutMe profileImgSrc='https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/headshots/Chris.jpg' name='Chris Culpepper' title='Co-Owner, Writer'>
            <div>
                <p>I'm Chris and I'm here to help! I’ve spent the last decade writing everything from action plans, to resumes, to blogs, to training manuals, &amp; everything in between. I’ll get your point across!</p>
                <p>Whatever your daily grind entails, there’s usually a better way to communicate your various programs.</p>
                <p>Contact us and let's do some writing! You're not alone. Together we can do it!</p>
            </div>
        </AboutMe>
        <AboutMe profileImgSrc='https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/headshots/Claudia.jpeg' name='Claudia Culpepper' title='Co-Owner, Accountability Manager'>
            <div>
                <p>Hi, I'm Claudia. Writers are a funny group! They're full of ideas and often can't get the words onto the page fast enough!</p>
                <p>That's good for you because Chris can create, refine, or edit your communication in record time. He really can get your point across!</p>
                <p>And I know. His smooth words have captivated me for over 30 years! ❤️</p>
                <p>As Co-Owner &amp; Accountability Manager, part of my job is to keep his "cartoon mind" on track so that your message receives the attention it deserves and is delivered just the way you want it! I can't wait to work with you!</p>
            </div>
        </AboutMe>
    </div>
    <NewsletterPromo width='normal' />
</div>)