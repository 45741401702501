import './DisplayCard.css'

type DisplayCardBase = {
    icon?: JSX.Element,
    title: string,
    size: string,
    children: JSX.Element
}

export const DisplayCard = ({icon, title, size, children}:DisplayCardBase) => (<div className={size} id='display-card'>
    <div id='display-card-icon'>{icon}</div>
    <h1>{title}</h1>
    {children}
</div>)