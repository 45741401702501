import './NewsletterPromo.css'
import { IGButton } from '../../Components/IGButton/IGButton'

const MailNotificationIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' id='mail-notification-icon' viewBox='0 0 512 512'><title>Mail Unread</title><path d='M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M112 160l144 112 87-65.67'/><circle cx='431.95' cy='128.05' r='47.95'/><path d='M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z'/></svg>)

export const NewsletterPromo = (props: {width:string}) => (<div id='newsletter-promo' className={`${props.width}`}>
    <MailNotificationIcon />
    <h1>Follow our Newsletter</h1>
    {props.width === 'normal' ?
        (<div>
            <p>Sign up <b>NOW</b> for our <b><i>FREE</i></b> monthly newsletter, <b><i>The Culpepper Communique</i></b>, for a regular dose of fun and positivity.</p>
            <p>Subscribers also receive my latest freebies!</p>
        </div>)
        :
        null
    }
    {props.width === 'wide' ?
        (<div><p>Sign up <b>NOW</b> for our <b><i>FREE</i></b> monthly newsletter, <b><i>The Culpepper Communique</i></b>, for a regular dose of fun and positivity. Subscribers also receive my latest freebies!</p></div>)
        :
        null
    }
    
    <IGButton label='Read and Follow on Substack →' url='https://chrisculpepper.substack.com' internal={false} />
</div>)