import './ContactForm.css'
import { useState } from 'react'

const WarningIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Warning</title><path d='M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path d='M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path d='M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z'/></svg>)

const CheckmarkIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Checkmark Circle</title><path d='M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z' fill='none' stroke='currentColor' strokeMiterlimit='10' stroke-width='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' stroke-width='32' d='M352 176L217.6 336 160 272'/></svg>)

const HourglassIcon = () => (<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><title>Hourglass</title><path d='M145.61 464h220.78c19.8 0 35.55-16.29 33.42-35.06C386.06 308 304 310 304 256s83.11-51 95.8-172.94c2-18.78-13.61-35.06-33.41-35.06H145.61c-19.8 0-35.37 16.28-33.41 35.06C124.89 205 208 201 208 256s-82.06 52-95.8 172.94c-2.14 18.77 13.61 35.06 33.41 35.06z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path d='M343.3 432H169.13c-15.6 0-20-18-9.06-29.16C186.55 376 240 356.78 240 326V224c0-19.85-38-35-61.51-67.2-3.88-5.31-3.49-12.8 6.37-12.8h142.73c8.41 0 10.23 7.43 6.4 12.75C310.82 189 272 204.05 272 224v102c0 30.53 55.71 47 80.4 76.87 9.95 12.04 6.47 29.13-9.1 29.13z'/></svg>)

export function ContactForm() {
    const [name, setName] = useState(String)
    const [email, setEmail] = useState(String)
    const [loading, setLoading] = useState(false)
    const [submissionError, setSubmissionError] = useState({
        status: false,
        message: ''
    })
    const [formSubmitted, setformSubmitted] = useState(false)
    const combinedState = {'System': heyChris, 'Name': name, 'Email address': email}

    function heyChris() {return (<p>
        Hi, Chris. You have a new submission from your contact form at culpeppercommunications.com. Here's the information they shared:
    </p>)}

    function verifyAndDeploySubmission(e:any) {
        e.preventDefault()
        setLoading(true)

        if (name !== '') {
            if (email !== '') {
                if (email.includes('@')) {
                    if (email.includes('.')) {
                        handleSubmission()
                    } else {
                        setLoading(false)
                        setSubmissionError({
                            status: true,
                            message: 'Sorry, there was a problem submitting that information. It looks like your email may be invalid.'
                        })
                    }
                } else {
                    setLoading(false)
                    setSubmissionError({
                        status: true,
                        message: 'Sorry, there was a problem submitting that information. It looks like your email may be invalid.'
                    })
                }
            } else {
                setLoading(false)
                setSubmissionError({
                    status: true,
                    message: 'Sorry, there was a problem submitting that information. It looks like the Email field is blank.'
                })
            }
        } else {
            setLoading(false)
            setSubmissionError({
                status: true,
                message: 'Sorry, there was a problem submitting that information. It looks like the Name field is blank.'
            })
        }
    }

    function handleSubmission() {
        const url = 'https://570rz81w5j.execute-api.us-east-2.amazonaws.com/Prod/submitForm'
        fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'applications/json',
                    'Accept': 'applications/json'
                },
                body: JSON.stringify(combinedState),
            })
            .then(response => response.text())
            .then(text => {
                console.log(text)
                formReset()
            })
            .catch((error) => {
                console.error(error)
            }
        )
    }

    function formReset() {
        // Clears inputs from form
        setName('')
        setEmail('')
        Array.from(document.querySelectorAll('input')).forEach(input => input.value='')

        setSubmissionError({
            status: false,
            message: ''
        })
        setLoading(false)
        setformSubmitted(true)
    }
    
    return (<form id='contact-form'>
        <label>Name</label>
        <input type='text' name='name' onChange={(e) => setName(e.target.value)} />
        <label>Email</label>
        <input type='text' name='email' onChange={(e) => setEmail(e.target.value)} />
        {
            loading ? null : <input type='button' value='Submit' onClick={(e:any) => verifyAndDeploySubmission(e)} />
        }
        <div id='spinner' className={`${loading ? 'visible' : 'invisible'} contact-form-message`}>
            
        </div>
        <div id='contact-form-message'>
            {loading ?
            (<div id='form-sending'>
                <HourglassIcon />
                <p>Sending message. Please do not close this window.</p>
            </div>)
            :
            null}
            {formSubmitted ?
            (<div id='form-done'>
                <CheckmarkIcon />
                <p>Successfully sent.</p>
            </div>)
            :  
            null}
            {submissionError.status ?
            (<div>
                <WarningIcon />
                <p>{submissionError.message}</p>
            </div>)
            :
            null}
        </div>
    </form>)
}