import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLayoutEffect } from 'react'

import { Nav } from '../Nav/Nav'
import { Footer } from '../Footer/Footer'
import { Error } from '../../Pages/Error/Error'
import { Home } from '../../Pages/Home/Home'
import { Samples } from '../../Pages/Samples/Samples'
import { WorkSampleStory } from '../WorkSampleStory/WorkSampleStory'
import { Blog } from '../../Pages/Blog/Blog'
import { About } from '../../Pages/About/About'
import { Contact } from '../../Pages/Contact/Contact'

const HelmetTitle = ({name, component}:{name:string, component:JSX.Element}) => {
    return (<HelmetProvider>
        <Helmet>
            <title>Culpepper Communications - {name}</title>
        </Helmet>
        {component}
    </HelmetProvider>)
}

const ScrollToTopWrapper = ({children}:any) => {
    const location = useLocation()

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    
    return children
}

const App = () => {
    return (<Router>
        <ScrollToTopWrapper>
            <Nav />
            <Routes>
                <Route
                    path='/'
                    element={<HelmetTitle name='Home' component={<Home />} />}
                />

                <Route
                    path='/samples'
                    element={<HelmetTitle name='Samples' component={<Samples />} />}
                />

                <Route
                    path='/samples/:workSampleStorySlug'
                    element={<HelmetTitle name='Samples' component={<WorkSampleStory />} />}
                />

                <Route
                    path='/blog'
                    element={<HelmetTitle name='Blog' component={<Blog />} />}
                />

                <Route
                    path='/about'
                    element={<HelmetTitle name='About' component={<About />} />}
                />

                <Route
                    path='/contact'
                    element={<HelmetTitle name='Contact' component={<Contact />} />}
                />

                <Route
                    path='*'
                    element={<HelmetTitle name='404: Page not found' component={<Error />} />}
                />
            </Routes>
            <Footer />
        </ScrollToTopWrapper>
    </Router>)
}

export default App