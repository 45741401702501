import './Home.css'
import { DisplayCard } from '../../Components/DisplayCard/DisplayCard'
import { NewsletterPromo } from '../../Components/NewsletterPromo/NewsletterPromo'
import { IGButton } from '../../Components/IGButton/IGButton'

export const Home = () => (<div id='home'>
    <img src='https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/writing-kit.jpg' alt='Mountains' className='mobileOnly' />
    <div id='home-hero'>
        <DisplayCard title='Culpepper Communications Can Help You!' size='large'>
            <div>
                <h2>Great Message, Are You Sure They Get It?</h2>
                <p>You have a message you want to get out. Whether it's to employees, customers, or the public, making it clear can be a real challenge. Miscommunication leads to wasted time and dollars.</p>
                <p>I come from 20 years of corporate safety work with all its advantages and disadvantages. I know what works as well as what doesn't.</p>
                <p>Whether it's training or compliance documentation, there's a lot of detail out there. You want your audience to understand your message or you've just wasted a lot of time writing it!</p>
                <p>That's where we come in. You're not alone. We can help!</p>
                <div id='home-card-buttons'>
                    <IGButton label='View Sample Work →' url='/samples' internal={true} />
                    <IGButton label='Contact Us Now →' url='/contact' internal={true} />
                </div>
            </div>
        </DisplayCard>
    </div>
    <NewsletterPromo width='wide' />
</div>)