import './Nav.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const NavLibrary = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'Samples',
        path: '/samples'
    },
    {
        name: 'Blog',
        path: '/blog'
    },
    {
        name: 'About',
        path: '/about'
    },
    {
        name: 'Contact',
        path: '/contact'
    }
]

export const Nav = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    function toggleMenu() {
        menuOpen ? setMenuOpen(false) : setMenuOpen(true)
    }

    const HamburgerMenu = () => (<div id='hamburger-menu' onClick={toggleMenu}>
        <svg viewBox='0 0 100 80' width='20' height='20'>
            <rect width='95' height='10'></rect>
            <rect y='30' width='95' height='10'></rect>
            <rect y='60' width='95' height='10'></rect>
        </svg>
    </div>)

    return (<div id='nav'>
        <div id='desktop-nav'>
            <Link to='/' id='nav-title'>
                <img src='https://culpepper-communications.s3.us-east-1.amazonaws.com/Images/headshots/Chris.jpg' alt='Chris Culpepper profile' />
                <h1 className='typewriter'>Culpepper Communications</h1>
            </Link>
            <ul>
                {NavLibrary.map((x, i) => (<li key={i}>
                    {x.name === 'Blog' ?
                        (<a href={x.path}>
                            {x.name}
                        </a>)
                    :
                        (<Link to={x.path}>
                            {x.name}
                        </Link>)
                    }
                </li>))}
            </ul>
        </div>
        
        <div id='mobile-nav'>
            <div id='mobile-nav-top'>
                <Link to='/'>
                    <h1>Culpepper Communciations</h1>
                </Link>
                <HamburgerMenu />
            </div>

            <ul id='mobile-nav-list' className={menuOpen === true ? 'visible' : 'invisible'}>
                {NavLibrary.map((x, i) => (<li key={i}>
                    {x.name === 'Blog' ?
                        (<a href={x.path}>
                            {x.name}
                        </a>)
                    :
                        (<Link to={x.path} onClick={toggleMenu}>
                            {x.name}
                        </Link>)
                    }
                </li>))}
            </ul>
        </div>
    </div>)
}